/* bootstrap overwrite */
@media (min-width: 768px) {
  .text-component {
    margin-left: auto !important;
    margin-right: auto !important;
    width: calc((8 / 12) * 100%) !important;
  }
}
.text-component h1::after {
  mask: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgzIiBoZWlnaHQ9IjgiIHZpZXdCb3g9IjAgMCAxODMgOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIgNS41MTk0NEM0Ny41IDAuODI2OTA0IDk3IDAuODI2OTM5IDE4MSA1LjUxOTU1IiBzdHJva2U9IiMyNzJBNDUiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==") !important;
  mask-repeat: no-repeat !important;
  filter: none !important;
  background-image: none !important;
  background-color: #d3ddf2 !important;
}
@media (min-width: 768px) {
  .text-component h1::after {
    mask: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMzMwIDExIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMiA4LjE2OEM4Ni4xNTIzIDEuOTYzMDMgMjAzLjExNiAtMi4xOTY5OSAzMjggOSIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSI0IiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K") !important;
    mask-repeat: no-repeat !important;
  }
}
.bg-dark .text-component h1::after {
  filter: none !important;
  background-color: #bcf0e8 !important;
}
.text-component ul li::before {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTIuMjMxIDUuMTNDMSA1LjEyOS4wMDEgNi4xNTIuMDAxIDcuNDE4IDAgOC42ODMuOTk2IDkuNzExIDIuMjI4IDkuNzE1YzEuMjMyLjAwMyAyLjIzLTEuMDIgMi4yMzEtMi4yODcgMC0xLjI2NS0uOTk3LTIuMjk0LTIuMjI5LTIuMjk3ek02LjA3NyAxLjAwNUM0Ljg0NSAxIDMuODQ3IDIuMDI1IDMuODQ2IDMuMjljMCAxLjI2Ni45OTcgMi4yOTQgMi4yMjkgMi4yOTggMS4yMzEuMDAzIDIuMjMtMS4wMiAyLjIzLTIuMjg3LjAwMS0xLjI2Ni0uOTk3LTIuMjk0LTIuMjI4LTIuMjk3ek05LjkwMiA1LjE0Yy0xLjIzMS0uMDA0LTIuMjMgMS4wMi0yLjIzIDIuMjg1LS4wMDEgMS4yNjYuOTk2IDIuMjk1IDIuMjI3IDIuMjk4IDEuMjMyLjAwMyAyLjIzLTEuMDIgMi4yMzEtMi4yODYgMC0xLjI2Ni0uOTk3LTIuMjk0LTIuMjI4LTIuMjk3ek02LjA1NSA5LjI3Yy0xLjIzMi0uMDAzLTIuMjMgMS4wMTktMi4yMyAyLjI4My0uMDAxIDEuMjY0Ljk5NyAyLjI5MiAyLjIyOCAyLjI5NiAxLjIzMi4wMDQgMi4yMy0xLjAxOCAyLjIzMS0yLjI4MyAwLTEuMjY0LS45OTctMi4yOTItMi4yMjktMi4yOTZ6IiBmaWxsPSIjQzlEMUUzIi8+PC9zdmc+) !important;
}